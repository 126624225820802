.candidateLink {
  padding: 1rem;
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e3e8;
  box-sizing: border-box;
  border-radius: 16px;
  background: #f4f4f8;
  font-size: 22px;
  font-family: 'PT Root UI';
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: left;
  }
}

.candidateLink:hover {
  background: white;
  color: inherit !important;
  cursor: pointer;
}

.candidateTitle {
  width: 100%;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.candidateSponsors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
  @media screen and (min-width: 768px) {
    overflow: hidden;
    justify-content: flex-start;
  }
}

.candidateSponsors span {
  font-size: 16px;
  font-weight: normal;
  margin-left: 10px;
  display: block;
}

.candidateSponsors strong {
  font-weight: bold;
}

.proposer {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.proposer a,
.proposerAddress {
  font-weight: bold;
  text-decoration: none;
  color: var(--brand-color-red);
}

.timestamp {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.sponsorCount.sponsorCountOverflow {
  @media screen and (min-width: 992px) {
    height: 100%;
    padding: 10px 40px;
    margin-left: -25px;
    background: linear-gradient(
      90deg,
      rgba(244, 244, 248, 0) 0%,
      rgba(244, 244, 248, 0.9) 15%,
      rgba(244, 244, 248, 1) 25%,
      rgba(244, 244, 248, 1) 100%
    );
  }
}

.candidateLink:hover .sponsorCount.sponsorCountOverflow {
  @media screen and (min-width: 992px) {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 15%,
      rgba(255, 255, 255, 1) 25%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}
.naVotesLabel {
  font-size: 0.8rem;
  font-family: monospace;
  font-weight: normal;
  opacity: 0.5;
  font-style: normal;
  padding: 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
}
