.proposalEditor {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 8px !important;
  outline: none !important;
  box-shadow: none !important;
}

.titleInput,
.bodyInput {
  padding: 0;
  border: none;
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
}

.titleInput {
  font-size: 1.25rem;
}

.propTitle {
  font-family: 'Londrina Solid';
}

.previewArea h3 {
  font-weight: 400;
  font-size: 14px;
  color: rgb(108, 117, 125);
}

.bodyInput {
  min-height: 340px !important;
}

.divider {
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.previewArea {
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 8px !important;
  outline: none !important;
  box-shadow: none !important;
}

.markdown h1,
.diffs h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2,
.diffs h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3,
.diffs h3 {
  font-size: 1.3rem;
}

.markdown img,
.diffs img {
  max-width: 100%;
  height: auto;
}

/* diff compare overrides */
.diffs {
  font-size: inherit;
  font-family: 'PT Root UI' !important;
  font-size: 1.1rem;
}
.diffs h1 {
  font-size: 1.7rem !important;
  margin-top: 1rem !important;
  font-weight: bold !important;
}

.diffs h2 {
  font-size: 1.5rem !important;
  margin-top: 1rem !important;
  font-weight: bold !important;
}

.diffs h3 {
  font-size: 1.3rem !important;
}

.diffs .section {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.diffs .section h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Londrina Solid';
}

.diffs .markdown {
  font-family: 'PT Root UI';
  font-size: 1.1rem;
}

.diffs .markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.diffs .markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.diffs .markdown h3 {
  font-weight: bold;
  font-size: 1.3rem;
}

.diffs .markdown img {
  max-width: 100%;
  height: auto;
}

.diffs .txnInfoText {
  color: var(--brand-gray-light-text);
  margin-left: -0.1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .diffs .txnInfoText {
    align-items: flex-start;
    margin-top: 1rem;
  }

  .diffs .txnInfoIcon {
    margin-top: 0.25rem;
    margin-right: 0.5rem;
  }
}

.diffs .txnInfoIcon {
  height: 18px;
  width: 18px;
  opacity: 0.5;
}

.diffs .txnInfoIconWrapper {
  width: 25px;
  display: flex;
  align-items: center;
}

.diffs .v3Proposal.section {
  padding-top: 0;
  margin-top: 0;
}
